<!-- =========================================================================================
    File Name: Events.vue
    Description: Календарь мероприятий
========================================================================================== -->


<template>
    <vx-card title="Мероприятия">
        <div class="vx-row">
            <div class="vx-col w-full lg:w-1/3 mb-base">
                <vx-card class="event-details-form" ref="eventDetailsForm" data-vv-scope="eventDetailsForm">
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">Организатор</label>
                            <div class="flex">
                                <v-select class="w-full mr-1"
                                        name="event_organizer" v-validate="{ required: true }" data-vv-as="'Организатор'"
                                        v-model="editedEvent.event_organizer"
                                        :getOptionLabel="opt => opt.table_fields['e-mail'] ? opt.table_fields['e-mail'] : $helpers.formatPhone(opt.table_fields['phone'])"
                                        :options="usersList"
                                        @search="onUserSearch"
                                        :filterable="false">
                                    <template slot="no-options">
                                        Поиск пользователей...
                                    </template>
                                    <template v-slot:option="option">
                                        <div>{{ $helpers.getFullUserName(option.table_fields) }}</div>
                                        <div class="pl-3">{{ $helpers.formatPhone(option.table_fields['phone']) }}</div>
                                        <div class="pl-3">{{ option.table_fields['e-mail'] }}</div>
                                    </template>
                                </v-select>
                                <vs-button @click="addCustomerPrompt = true" type="filled" icon="person_add"></vs-button>
                            </div>
                            <span class="text-danger">{{ errors.first('event_organizer') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">Тип мероприятия</label>
                            <vs-select 
                                name="event_type" v-validate="{ required: true }" data-vv-as="'Тип мероприятия'"
                                v-model="editedEvent.event_type" class="w-full select-large">
                                <vs-select-item 
                                    :key="index" :value="item.id" :text="item.name" v-for="(item,index) in eventTypesList" 
                                    class="w-full" />
                            </vs-select>
                            <span class="text-danger">{{ errors.first('event_type') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">Группа</label>
                            <v-select class="w-full"
                                    name="event_group" data-vv-as="'Группа'"
                                    v-model="editedEvent.event_group"
                                    @search="onEventGroupsSearch"
                                    label="name"
                                    :filterable="false"
                                    :options="eventGroupsList"
                                    :create-option="group => ({ name: group})"
                                    taggable>
                                <template slot="no-options">
                                    Поиск групп мероприятий...
                                </template>
                            </v-select>
                            <span class="text-danger">{{ errors.first('event_group') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-input 
                                name="name" v-validate="{ required: true }" data-vv-as="'Название'"
                                class="w-full" label="Название" v-model="editedEvent.name" />
                            <span class="text-danger">{{ errors.first('name') }}</span>
                        </div>
                    </div>

                    <div class="vx-row mb-6" v-show="isRecurrentEventCreationAvailable">
                        <div class="vx-col w-full mb-2">
                            <vs-checkbox ref="recurrent" v-model="editedEvent.recurrent">Создать повторяющиеся события</vs-checkbox>
                        </div>
                        <div class="vx-col w-full" v-show="editedEvent.recurrent">
                            <vs-button class="w-full" type="border" @click="setIntervalsPrompt = true;">
                                {{ editedEvent.recurrent_props.times.length > 1 ? 'Изменить' : 'Задать' }} интервалы
                            </vs-button>
                            <div v-show="!isRecurrentOptionsValid" class="text-danger">Интервалы заданы не корректно</div>
                            <div class="text-danger">{{ errors.first('until') }}</div>

                            <vs-popup title="Повторяющиеся события"
                                :active.sync="setIntervalsPrompt">
                                    <div class="vx-row mb-6">
                                        <div class="vx-col sm:w-1/3 w-full">
                                            <span>Интервал</span>
                                        </div>
                                        <div class="vx-col sm:w-2/3 w-full">
                                            <vs-select v-model="editedEvent.recurrent_props.interval" class="w-full select-large">
                                                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in recurrentFreqsList" class="w-full" />
                                            </vs-select>
                                            <div class="text-danger" v-show="!isRecurrentOptionsValid">Интервалы заданы не корректно</div>
                                        </div>
                                    </div>

                                    <template v-if="editedEvent.recurrent_props.interval == 'P1W'">
                                        <div class="vx-row mb-6" :key="day.dow" v-for="day in weekdaysList">
                                            <div class="vx-col sm:w-1/3 w-full">
                                                <vs-checkbox class="inline-flex" :vs-value="day" v-model="editedEvent.recurrent_props.times">{{day.text}}</vs-checkbox>
                                            </div>
                                            <div class="vx-col sm:w-1/3 w-full">
                                                <flat-pickr class="w-full" 
                                                    v-model="day.start" :config="configTimePicker" />
                                            </div>
                                            <div class="vx-col sm:w-1/3 w-full">
                                                <flat-pickr class="w-full" 
                                                    v-model="day.end" :config="configTimePicker" />
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="editedEvent.recurrent_props.interval == 'P1D'">
                                        <div class="vx-row mb-6">
                                            <div class="vx-col sm:w-1/3 w-full">
                                                Время
                                            </div>
                                            <div class="vx-col sm:w-1/3 w-full">
                                                <flat-pickr class="w-full" 
                                                    v-model="editedEvent.recurrent_props.times[0].start" 
                                                    :config="configTimePicker" />
                                            </div>
                                            <div class="vx-col sm:w-1/3 w-full">
                                                <flat-pickr class="w-full" 
                                                    v-model="editedEvent.recurrent_props.times[0].end" 
                                                    :config="configTimePicker" />
                                            </div>
                                        </div>
                                    </template>

                                    <div class="vx-row mb-6">
                                        <div class="vx-col sm:w-1/3 w-full">
                                            <span>Дата до</span>
                                        </div>
                                        <div class="vx-col sm:w-2/3 w-full">
                                            <flat-pickr class="w-full" 
                                                name="until" v-validate="{required: editedEvent.recurrent}" data-vv-as="'Дата до'"
                                                v-model="editedEvent.recurrent_props.until" :config="configDateTimePicker" />
                                            <span class="text-danger">{{ errors.first('until') }}</span>
                                        </div>
                                    </div>
                            </vs-popup>
                        </div>
                    </div>

                    <template v-if="!editedEvent.recurrent">
                        <div class="vx-row mb-6">
                            <div class="vx-col w-full">
                                <label class="vs-input--label">Время начала</label>
                                <flat-pickr class="w-full" 
                                    ref="startPickr"
                                    name="start" 
                                    v-validate="'date_format:dd/MM/yyyy HH:mm|before:endPickr,true|required'"
                                    data-vv-as="'Время начала'"
                                    v-model="editedEvent.start" 
                                    :config="configDateTimePicker" />
                                <span class="text-danger">{{ errors.first('start') }}</span>
                            </div>
                        </div>
                        <div class="vx-row mb-6">
                            <div class="vx-col w-full">
                                <label class="vs-input--label">Время завершения</label>
                                <flat-pickr class="w-full" 
                                    ref="endPickr"
                                    name="end" 
                                    v-validate="'date_format:dd/MM/yyyy HH:mm|after:startPickr,true|required'"
                                    data-vv-as="'Время завершения'"
                                    v-model="editedEvent.end" 
                                    :config="configDateTimePicker" />
                                <span class="text-danger">{{ errors.first('end') }}</span>
                            </div>
                        </div>
                    </template>

                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">Тариф</label>
                            <v-select class="w-full" 
                                name="time_tariff" v-validate="{ required: true }" data-vv-as="'Тариф'"
                                :options="timeTariffsList" v-model="editedEvent.time_tariff" label="name" 
                                :clearable="false" />
                            <span class="text-danger">{{ errors.first('time_tariff') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">Цена билета</label>
                            <vs-input-number
                                name="price" v-validate="{ required: editedEvent.time_tariff.id == $globals.EMPTY_TARIFF_ID }" data-vv-as="'Цена билета'"
                                :step="0.01" v-model="editedEvent.price" />
                            <span class="text-danger">{{ errors.first('price') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">Лимит посетителей</label>
                            <vs-input-number 
                                name="visitors_limit" v-validate="{ required: true }" data-vv-as="'Лимит посетителей'" min="1"
                                v-model="editedEvent.visitors_limit" />
                            <span class="text-danger">{{ errors.first('visitors_limit') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6" v-if="this.editedEvent.event_type === this.$globals.MIX_EVENT_TYPE">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">Количество гостей</label>
                            <vs-input-number 
                                name="guests_limit" v-validate="{ required: true }" data-vv-as="'Количество гостей'" min="0" :max="editedEvent.visitors_limit"
                                v-model="editedEvent.guests_limit" />
                            <span class="text-danger">{{ errors.first('guests_limit') }}</span>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">Место проведения</label>
                            <vs-select v-model="editedEvent.event_venue" class="w-full select-large">
                                <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in eventVenuesList" class="w-full" />
                            </vs-select>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-textarea class="w-full" label="Примечание" v-model="editedEvent.event_comment" />
                        </div>
                    </div>

                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">Выберите теги для добавления</label>
                            <div class="flex">
                                <v-select class="w-full" multiple
                                name="tags_list" data-vv-as="'Выберите теги для добавления'"
                                :options="tagsList" v-model="defaultEventObj.new_event_tag" label="name" 
                                />
                            </div>
                        </div>
                    </div>

                    <div class="vx-row mb-6">
                        <div class="vx-col w-full" v-if="editedEvent.event_tags">
                            <label class="vs-input--label">Теги выбранного мероприятия:</label>
                            <div class="vx-col w-full"><b>{{editedEvent.event_tags}}</b></div>
                        </div>
                        <div class="vx-col w-full" v-else></div>
                    </div>

                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <vs-button color="success" class="mr-3 mb-2" :disabled="eventUpdating" @click="formUpdateEvent">{{editedEvent.id ? 'Сохранить' : 'Создать'}}</vs-button>
                            <vs-button class="mr-3 mb-2" v-if="editedEvent.event_prepay_order" @click="openPrepayOrder()">Заказ</vs-button>
                            <vs-button color="warning" type="border" class="mr-3 mb-2" @click="resetForm">Сбросить</vs-button>
                            <vs-button color="danger" type="border" class="mb-2" @click="deleteEventPrompt = true">Удалить</vs-button>
                        </div>
                    </div>
                </vx-card>

                <div class="vx-card" style="padding: 1.5rem; margin-top: 20px;">
                    <label class="vs-input--label">Формирование списка мероприятий в формате CSV. Выберите дату и время</label>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">от:</label>
                            <flat-pickr class="w-full" 
                                name="cstart" 
                                v-model="csvStart" 
                                :config="configcsvDateTimePicker" />
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">до:</label>
                            <flat-pickr class="w-full" 
                                name="cend" 
                                v-model="csvEnd" 
                                :config="configcsvDateTimePicker" />
                        </div>
                    </div>
                    <div class="flex">
                        <vs-button class="" style="width: 100%;" @click="downloadCSV()">Скачать CSV</vs-button>
                    </div>
                </div>
                
            </div>
            <div class="vx-col w-full lg:w-2/3 mb-base">
                <FullCalendar 
                    ref="calendar"
                    defaultView="dayGridMonth" 
                    :header="header" 
                    :events="events"
                    :locale="locale"
                    :editable="true"
                    :eventRender="renderEvent"
                    @eventResize="dragUpdateEvent"
                    @eventDrop="dragUpdateEvent"
                    @eventClick="selectEvent"
                    :snapDuration="snapDuration"
                    :nowIndicator="true"
                    :selectable="true"
                    :selectMirror="true"
                    @select="selectDateRange"
                    :unselectCancel="'.event-details-form'"
                    :plugins="calendarPlugins" 
                />
            </div>

            <!-- Попап удаления мероприятия -->
            <vs-prompt
                :active.sync="deleteEventPrompt"
                @accept="deleteEvent"
                title="Удаление мероприятия"
                accept-text="Удалить"
                cancel-text="Отмена">
                    Вы уверены что хотите удалить мероприятие?
            </vs-prompt>

            <!-- Попап добавления организатора -->
            <vs-popup title="Регистрация пользователя"
                :active.sync="addCustomerPrompt">
                <registration ref="customerRegistration" :startSession="false"
                    @userRegistered="onUserRegistered"/>
            </vs-popup>

        </div>
    </vx-card>
</template>


<script>
// TODO Cделать чтоб модель таймпикеров была в виде Date объекта, а не строкой
// TODO Исправить как задаются все дефолтные значения - defaultEventObj.time_tariff - багует иногда
// TODO Может задавать дефолтную группу не в бэкенде, а здесь

import qs from 'qs';
import _debounce from 'lodash/debounce';

import FullCalendar from '@fullcalendar/vue';
import ruLocale from '@fullcalendar/core/locales/ru';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian as RussianLocale} from 'flatpickr/dist/l10n/ru.js';

import Registration from '@/views/pages/Registration.vue';
const encoding = require('encoding');

export default {
    components: {
        FullCalendar,
        flatPickr,
        Registration
    },
    data() {
        return {
            csvStart: '',
            csvEnd: '',
            cstart: undefined,
            cend: undefined,
            eventUpdating: false,
            addCustomerPrompt: false,
            deleteEventPrompt: false,
            setIntervalsPrompt: false,

            baseFilter: {
                p: 0,
                per_page: 10,
                with_active_visits: "all"
            },
            usersList: [],
            eventTypesList: [],
            eventVenuesList: [],
            tagsList:[],
            eventTagsList:[],
            eventGroupsList: [],
            recurrentFreqsList: [
                {value:'P1W', text:'Еженедельно'},
                {value:'P1D', text:'Ежедневно'}
            ],
            weekdaysList: [
                {dow:'monday', text:'Пн'},
                {dow:'tuesday', text:'Вт'},
                {dow:'wednesday', text:'Ср'},
                {dow:'thursday', text:'Чт'},
                {dow:'friday', text:'Пт'},
                {dow:'saturday', text:'Сб'},
                {dow:'sunday', text:'Вс'}
            ],
            editedEvent: null,

            events: {
                url: '/udata/catalog/get_events/.json',
                method: 'POST',
                success: function(response) { 
                    return response.items ? Object.values(response.items) : []; 
                },
                failure: (response) => {
                    this.$vs.notify({
                        color:'warning',
                        title:'Ошибка получения списка мероприятий',
                        text:response.message
                    })
                }
            },
            header: {
                left:   'prev,next today',
                center: 'title',
                right:  'dayGridMonth timeGridWeek timeGridDay'
            },
            snapDuration: { minutes: 5 },
            calendarPlugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
            locale: ruLocale,

            configDateTimePicker: {
                enableTime: true,
                dateFormat: 'd/m/Y H:i',
                locale: RussianLocale,
                allowInput: true,
                disableMobile: true
            },
            configTimePicker: {
                enableTime: true,
                noCalendar: true,
                locale: RussianLocale,
                allowInput: true,
                disableMobile: true
            },
            configcsvDateTimePicker: {
                enableTime: true,
                dateFormat: 'd/m/Y H:i',
                locale: RussianLocale,
                allowInput: true,
                disableMobile: false
            },

            defaultEventObj: {
                start: undefined,
                end: undefined,
                event_group: null,
                event_organizer: {
                    id: 182,
                    table_fields: {
                        'e-mail': 'admin'
                    }
                },
                event_tag: [],
                new_event_tag: [],
                event_venue: null,
                event_type: 7570,
                recurrent: false,
                recurrent_props: {
                    interval: 'P1W',
                    times: []
                },
                visitors_limit: 1,
                time_tariff: this.$store.state.timeTariffsList[0],
                price: 0,
            }
        }
    },
    computed: {
        timeTariffsList() {
            if (this.editedEvent.price > 0) {
                return this.$store.state.timeTariffsList.filter((e) => e.id === this.$globals.EMPTY_TARIFF_ID);
            } else {
                return this.$store.state.timeTariffsList;
            }
        },
        isGuestsEventType() {
            return (this.editedEvent.event_type === this.$globals.RENT_EVENT_TYPE) || (this.editedEvent.event_type === this.$globals.MIX_EVENT_TYPE) ;
        },
        isRecurrentEventCreationAvailable() {
            return !this.editedEvent.id && this.editedEvent.event_type === this.$globals.CLUB_EVENT_TYPE;
        },
        isRecurrentOptionsValid() {
            function checkTimes(interval) {
                if (!interval.start || !interval.end) return false;

                let start_time = new Date("01/01/2020 "+interval.start);
                let end_time = new Date("01/01/2020 "+interval.end);
                if (start_time < end_time) return true;

                return false;
            }
        
            if (!this.editedEvent.recurrent) {
                return true;
            } else {
                if (this.editedEvent.recurrent_props.interval === 'P1D') {
                    return checkTimes(this.editedEvent.recurrent_props.times[0]);
                }
                if (this.editedEvent.recurrent_props.interval === 'P1W') {
                    let times = this.editedEvent.recurrent_props.times;
                    if (times.length < 1) return false;
                    for (const interval of times) {
                        if (checkTimes(interval) === false) return false;
                    }
                    return true;
                }

                return false;
            }
        }
    },
    watch: {
        'editedEvent.recurrent_props.interval'(val) {
            switch (val) {
                case 'P1D':
                    this.editedEvent.recurrent_props.times = [{ dow: 'all' }];    
                    break;
                case 'P1W':
                    this.editedEvent.recurrent_props.times = [];
                    break;
                default:
                    break;
            }
        },
        'editedEvent.event_type'(val) {
            this.$set(this.editedEvent, 'recurrent', false);
            switch (val) {
                case this.$globals.RENT_EVENT_TYPE:
                    this.editedEvent.guests_limit = this.editedEvent.visitors_limit;
                    break;
                case this.$globals.MIX_EVENT_TYPE:
                    if (val < this.editedEvent.guests_limit) {
                        this.editedEvent.guests_limit = val;
                    }
                    break;
                case this.$globals.CLUB_EVENT_TYPE:
                    this.$set(this.editedEvent, 'guests_limit', undefined);
                    break;
            }
        },
        'editedEvent.visitors_limit'(val) {
            switch (this.editedEvent.event_type) {
                case this.$globals.RENT_EVENT_TYPE:
                    this.editedEvent.guests_limit = this.editedEvent.visitors_limit;
                    break;
                case this.$globals.MIX_EVENT_TYPE:
                    if (val < this.editedEvent.guests_limit) {
                        this.editedEvent.guests_limit = val;
                    }
                    break;
            }
        },
        'editedEvent.recurrent'(val) {
            if (val) {
                this.editedEvent.start = undefined;
                this.editedEvent.end = undefined;
            }
        },
        'editedEvent.start'() {
            if (this.editedEvent.end < this.editedEvent.start) {
                this.editedEvent.end = this.editedEvent.start;
            }
        },
        'editedEvent.end'() {
            if (this.editedEvent.end < this.editedEvent.start) {
                this.editedEvent.start = this.editedEvent.end;
            }
        },
        'editedEvent.price'(val) {
            if (val > 0) {
                this.editedEvent.time_tariff = this.timeTariffsList[0];
            }
        }
    },
    methods: {
        downloadCSV() {
            let data = {
                'data': {
                    'csvstart': this.csvStart,
                    'csvend': this.csvEnd
                }
            }
            this.$http({
                method: 'post',
                data: qs.stringify(data),
                url: '/udata/catalog/export_events/.json',
                withCredentials: true
            }).then((response) => {
                try {
                    const csv = encoding.convert(response.data.result, 'WINDOWS-1251', 'UTF-8');
                    let filename = ('список_мероприятий_' + this.csvStart + '_-_' + this.csvEnd + '__' + this.$helpers.formatDateTime(this.$store.state.now) + '.csv').replace(/ /g, '_');
                    this.$helpers.downloadCSV(csv, filename);
                } catch (err) {
                    this.$vs.notify({
                        color:'danger',
                        title:'Ошибка',
                        text:err
                    });
                }
                if (response.data.error) {
                    this.$vs.notify({
                        title:'Ошибка при формировании CSV',
                        text: response.data.error,
                        color:'warning',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'
                    })          
                } else {
                    this.editedEvent.id = null;
                }
            })
            .catch((error) => {
                this.$refs.calendar.getApi().refetchEvents();
                this.$vs.notify({
                    title:'Ошибка при формировании CSV',
                    text: error,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'
                })
            });
        },
        onUserRegistered(val) {
            this.editedEvent.event_organizer = { 
                id: val.user_id,
                table_fields: {
                    'e-mail': val['e-mail'],
                    'phone': val['phone']
                }
            };
            this.addCustomerPrompt = false;
        },
        openPrepayOrder() {
            this.$store.dispatch('getOrder', [
                this.editedEvent.event_prepay_order,
                function(){},
                () => { this.$router.push({ name: 'edit-order' }); } 
            ]);
        },
        async dragUpdateEvent(eventInfo) {
            await this.selectEvent(eventInfo);
            this.formUpdateEvent();
        },
        async formUpdateEvent() {
            // custom "validation" for recurrent events
            if (!this.isRecurrentOptionsValid) return false;

            let result = await this.$validator.validateAll();
            if (result) { 
                let event = this.editedEvent;
                let payload = {
                    data: {
                        new: {
                            id: event.id,
                            page_id: event.page_id,
                            manager_id: JSON.parse(localStorage.getItem('userInfo')).user_id,
                            event_organizer: event.event_organizer.id,
                            event_type: event.event_type,
                            event_group: {},
                            name: event.name,
                            visitors_limit: event.visitors_limit,
                            guests_limit: event.guests_limit,
                            time_tariff: event.time_tariff.id,
                            event_start_time: event.start,
                            event_end_time: event.end,
                            price: event.price,
                            event_venue: event.event_venue,
                            event_comment: event.event_comment,
                            recurrent: event.recurrent,
                            recurrent_props: this.editedEvent.recurrent_props,
                            tags_list: JSON.stringify(this.defaultEventObj.new_event_tag)
                        }
                    }
                };
                if (event.event_group) {
                    payload.data.new.event_group.name = event.event_group.name;
                    payload.data.new.event_group.page_id = event.event_group.id;
                }
                
                await this.updateEvent(payload);
                this.$refs.calendar.getApi().unselect();
            } else {
                this.$refs.calendar.getApi().refetchEvents();
            }
        },
        updateEvent(payload) {
            this.eventUpdating = true;
            this.$vs.loading({ container: this.$refs.eventDetailsForm.$el});

            // HACK После обновления UMI для сохранения полей с типом symlink теперь значение обязательно должно быть массивом
            payload.data.new.time_tariff = [payload.data.new.time_tariff];
        
            this.$http({
                method: 'post',
                url: '/udata/catalog/event/.json',
                data: qs.stringify(payload, {
                    serializeDate: function (d) { return d.toString(); } 
                }),
                withCredentials: true
            }).then((response) => {
                this.eventUpdating = false;
                this.$vs.loading.close(this.$refs.eventDetailsForm.$el);
                if (response.data.error) {
                    this.$vs.notify({
                        title:'Ошибка при сохранении мероприятия',
                        text: response.data.error,
                        color:'warning',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'
                    })          
                } 
                if (response.data.event_prepay_order) {
                    let order_id = response.data.event_prepay_order;
                    this.$store.dispatch('getOrder', [
                        order_id,
                        function(){},
                        () => { this.$router.push({ name: 'edit-order' });} 
                    ]);
                } else {
                    this.$refs.calendar.getApi().refetchEvents();
                }
            })
            .catch((error) => {
                this.$vs.notify({
                    title:'Ошибка при сохранении мероприятия',
                    text: error,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'
                });
                this.$refs.calendar.getApi().refetchEvents();
            });
        },
        deleteEvent() {
            this.$vs.loading({ container: this.$refs.eventDetailsForm.$el});
            this.$http({
                method: 'get',
                url: '/udata/catalog/delete_event/' + this.editedEvent.id + '.json',
                withCredentials: true
            }).then((response) => {
                this.$refs.calendar.getApi().refetchEvents();
                this.$vs.loading.close(this.$refs.eventDetailsForm.$el);
                if (response.data.error) {
                    this.$vs.notify({
                        title:'Ошибка при удалении мероприятия',
                        text: response.data.error,
                        color:'warning',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'
                    })          
                } else {
                    this.editedEvent.id = null;
                }
            })
            .catch((error) => {
                this.$refs.calendar.getApi().refetchEvents();
                this.$vs.notify({
                    title:'Ошибка при удалении мероприятия',
                    text: error,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'
                })
            });
        },
        selectEvent(eventInfo) {
            this.editedEvent = Object.assign({}, this.defaultEventObj, 
                {
                    id: eventInfo.event.id,
                    start: eventInfo.event.start,
                    end: eventInfo.event.end,
                },
                eventInfo.event.extendedProps,
            );
            this.editedEvent.event_tags = eventInfo.event.extendedProps.event_tags;
            this.$validator.validate();
        },
        selectDateRange(selectionInfo) {
            this.editedEvent.start = selectionInfo.start;
            this.editedEvent.end = selectionInfo.end;
        },
        renderEvent(info) { 
            if (info.view.type === "timeGridDay" && info.event.extendedProps.time_tariff) {
                let txt = document.createTextNode("Тариф: " + info.event.extendedProps.time_tariff.name);
                let div = document.createElement("div");
                div.appendChild(txt);
                info.el.querySelector('.fc-content').appendChild(div);
            }
        },
        resetForm() {
            let now = new Date();
            this.defaultEventObj.start = now.valueOf();
            this.defaultEventObj.end = now.valueOf();
            this.editedEvent = Object.assign({}, this.defaultEventObj);
        },
        getEventTypesList() {
            fetch("/udata/catalog/get_select_list/event-types/.json")
            .then(response => response.json())
            .then(response => {
                if (response.items) {
                    this.eventTypesList = Object.values(response.items);
                }
            })
            .catch(e => {
                this.$vs.notify({
                    color:'danger',
                    title:'Не удалось получить список типов мероприятий',
                    text: e
                })
            });
        },
        async getEventVenuesList() {
            try {
                let response;
                response = await fetch("/udata/catalog/get_select_list/event-venues/.json");
                response = await response.json();
                if (response.items) {
                    this.eventVenuesList = Object.values(response.items);
                    this.defaultEventObj.event_venue = this.eventVenuesList[0].id;
                    this.editedEvent.event_venue = this.eventVenuesList[0].id;
                }
            } catch (error) {
                this.$vs.notify({
                    color:'danger',
                    title:'Не удалось получить список типов мероприятий',
                    text: error
                })
            }
        },
        async getAllTags() {
            await this.$http({
                method: 'get',
                url: '/udata/users/get_all_tags/.json',
                withCredentials: true
            }).then((response) => {
                if (response.data.tags) {
                    this.tagsList = Object.values(response.data.tags);
                } else {
                    this.$vs.notify({
                    title:'Внимание',
                    text: 'Создайте теги через "Менеджер тегов", чтобы иметь возможность добавлять их к мероприятиям',
                    color:'warning',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'
                    })
                }
            })
            .catch((error) => {
                this.$vs.notify({
                title:'Ошибка',
                text: error,
                color:'danger',
                iconPack: 'feather',
                icon:'icon-alert-circle'
                })
            });
        },
        onEventGroupsSearch(search, loading) {
            loading(true);
            this.eventGroupsSearch(loading, search);
        },
        onUserSearch(search, loading) {
            loading(true);
            this.userSearch(loading, search);
        },
        eventGroupsSearch: _debounce(function(loading, search) {
            if (!search) {
                loading(false);
                return;
            }
            let payload = {
                search_string: search
            };
            this.$http({
                method: "post",
                url: "/udata/catalog/get_select_list/timer-event-group/pages.json",
                data: qs.stringify(payload),
                withCredentials: true
            })
            .then(response => {
                loading(false);
                if (response.data.items) {
                    this.eventGroupsList = Object.values(response.data.items);
                } else {
                    this.$vs.notify({
                        title: "Ошибка eventGroupsSearch",
                        text: 'Не удалось получить список групп мероприятий',
                        color: "warning",
                        iconPack: "feather",
                        icon: "icon-alert-circle"
                    });
                }
            })
            .catch(error => {
                loading(false);
                this.$vs.notify({
                    title: "Ошибка eventGroupsSearch",
                    text: error,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-alert-circle"
                });
            });
        }, 350),
        userSearch: _debounce(function(loading, search) {
            if (!search) {
                loading(false);
                return;
            }
            let payload = Object.assign({
                    search_string: search
                }, 
                this.baseFilter
            );
            this.$http({
                method: "post",
                url: "/udata/users/get_clients/all/.json",
                data: qs.stringify(payload),
                withCredentials: true
            })
            .then(response => {
                loading(false);
                if (!response.data.error) {
                    this.usersList = Object.values(response.data.items);
                } else {
                    this.$vs.notify({
                        title: "Ошибка eventGroupsSearch",
                        text: response.data.error,
                        color: "warning",
                        iconPack: "feather",
                        icon: "icon-alert-circle"
                    });
                }
            })
            .catch(error => {
                loading(false);
                this.$vs.notify({
                    title: "Ошибка eventGroupsSearch",
                    text: error,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-alert-circle"
                });
            });
        }, 350)
    },
    created() {
        this.getEventTypesList();
        this.getEventVenuesList();
        this.getAllTags();
        this.resetForm();
    },
    mounted() {
        this.resetForm();
    }
}
</script>

<style lang='scss'>
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
</style>
